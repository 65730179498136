@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;1,400&display=swap");

* {
    padding: 0;
    margin: 0;
    font-family: "Jost",sans-serif;
}

body {
    background-color: #f3f2ef;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    width: 100vw;
    font-family: "Jost",sans-serif;

}

html {
    font-size: 100%;
    height: 100%;
}

html {
    background: #eee;
}

.box {
    width: 25vh;
    height: 60vh;
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.2);
    background: #FFD580;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    min-width: 20rem;
    min-height: 35rem;
    padding:10px;
}
.box h1{
    color:#2f0909;
}
.wave {
    opacity: 0.3;
    position: absolute;
    top: 120%;
    left: 50%;
    background: white;
    width: 50rem;
    height: 50rem;
    margin-left: -25rem;
    margin-top: -20rem;
    transform-origin: 50% 48%;
    border-radius: 43%;
    animation: drift 3000ms infinite linear;
    z-index: 1;
}
.wave·—three {
    animation: drift 5000ms infinite linear;
    z-index: 2 !important;

}
.wave·—two {
    animation: drift 5000ms infinite linear;
    opacity: 0.1;
    z-index: 3 !important;

}

.box:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    transform: translate3d(0, 0, 0);
}
.errorMsg {
    text-align: center;
    

}

@keyframes drift {
    from {
        transform: rotate(0deg);
    }
    from {
        transform: rotate(360deg);
    }
}

.inputFeild{
    padding:10px;
    width:90%;
    margin:20px 0;
    color:#2f0909;
}