@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400&family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=East+Sea+Dokdo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
    padding: 0;
    margin: 0;
    font-family: "Jost",sans-serif;
}

body {
    background-color: #f3f2ef;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    width: 100vw;
    font-family: "Jost",sans-serif;

}

html {
    font-size: 100%;
    height: 100%;
}

html {
    background: #eee;
}

.box {
    width: 25vh;
    height: 60vh;
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.2);
    background: #FFD580;
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    min-width: 20rem;
    min-height: 35rem;
    padding:10px;
}
.box h1{
    color:#2f0909;
}
.wave {
    opacity: 0.3;
    position: absolute;
    top: 120%;
    left: 50%;
    background: white;
    width: 50rem;
    height: 50rem;
    margin-left: -25rem;
    margin-top: -20rem;
    -webkit-transform-origin: 50% 48%;
            transform-origin: 50% 48%;
    border-radius: 43%;
    -webkit-animation: drift 3000ms infinite linear;
            animation: drift 3000ms infinite linear;
    z-index: 1;
}
.wave·—three {
    -webkit-animation: drift 5000ms infinite linear;
            animation: drift 5000ms infinite linear;
    z-index: 2 !important;

}
.wave·—two {
    -webkit-animation: drift 5000ms infinite linear;
            animation: drift 5000ms infinite linear;
    opacity: 0.1;
    z-index: 3 !important;

}

.box:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.errorMsg {
    text-align: center;
    

}

@-webkit-keyframes drift {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    from {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes drift {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    from {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.inputFeild{
    padding:10px;
    width:90%;
    margin:20px 0;
    color:#2f0909;
}
